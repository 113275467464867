<template>
  <FullScreenDialog 
    v-model="serviceTypeDialog" 
    title="Nuova Famiglia" 
    :routeFather="pathToGoBack"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="tab in serviceTypeTabs" :key="tab.key">{{ tab.title }}</v-tab>
      </v-tabs>
    </template>
    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in serviceTypeTabs" :key="tabItem.key">
          <v-card flat>
            <component :is="tabItem.component" :passChange="passChange"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-slot:footer-actions>
      <v-spacer></v-spacer>
      <v-btn text color="error" @click="$router.push({ path: pathToGoBack })">Annulla</v-btn>
      <v-btn text color="default" :disabled="!formValid" :loading="loading" @click="submitForm">Salva</v-btn>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import serviceTypesService from '@/services/serviceTypes/serviceTypes.service.js'
import serviceTypeForm from '@/services/serviceTypes/serviceType.form.js'
import GeneralFormTab from '@/components/areas/registries/serviceTypes/form/GeneralFormTab.vue'
export default {
  name: "ServiceTypesRegistryNewForm",
  components: {
    FullScreenDialog,
    GeneralFormTab
  },
  data: function () {
    return {
      tab: null,
      serviceTypeDialog: this.openDialog,
      serviceTypeTabs: [
        {
          key: 0,
          title: "Generale",
          name: "general",
          component: "GeneralFormTab"
        }
      ],
      formValid: false,
      loading: false,
      serviceType: undefined,
      originalUrl: window.location.pathname,
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)
    let self = this
    serviceTypeForm.resetServiceType()
    serviceTypeForm.on('update', function (data) {
      self.serviceType = data.serviceType
    })
    serviceTypeForm.on('valid-change', function (data) {
      self.formValid = data
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    pathToGoBack: {
      type: String,
      default: "/registries"
    },
    tabName: {
      type: String,
      default: 'general'
    }
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for (let i = 0; i < this.serviceTypeTabs.length; i++) {
        if (this.serviceTypeTabs[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      if (!(!!this.serviceType.mediumFidelityDays == !!this.serviceType.highFidelityDays))
        this.$delegates.snackbar("Inserire entrambi i valori di fidelizzazione")
      else if (this.serviceType.mediumFidelityDays <= (this.serviceType.highFidelityDays + 1))
        this.$delegates.snackbar("I valori di fidelizzazione sono errati")
      else {
        this.loading = true
        serviceTypesService.create(this.serviceType).then((result) => {
          this.loading = false
          this.$router.push({ path: this.pathToGoBack })
        })
      }
    },
    passChange() {
      return;
    }
  },
  watch: {
    openDialog(newVal) {
      this.serviceTypeDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.serviceTypeTabs[newVal].name);
      } catch (err) {
        console.log('try setting the tab name but something goes wrong')
      }
    }
  },
  computed: {
  },
};
</script>